import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import { STATUS_ACTIVATED_NEW } from "consts/general";
import { COLOR_PRIMARY } from "consts/config";
import { ICON_NEW } from "consts/icons";

export default function MainMenuItem({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => {
    setIsExpanded((_isExpanded) => !_isExpanded);
  };

  if (item.render) {
    return item.render();
  }

  const BtnComponent = item.path ? Link : StyledButton;

  return (
    <StyledLi className={classNames({ active: isExpanded })}>
      {item.icon && <StyledIcon className={item.icon} aria-hidden="true" />}
      {/* Case ngoai le order tu team hoc10 */}
      {item.title == "Mua sách" ? (
        <a
          title="Mua sách"
          class=""
          target="_blank"
          href="http://sachcanhdieu.vn/"
        >
          Mua sách
        </a>
      ) : (
        <BtnComponent
          to={item.path}
          title={item.title}
          onClick={item.onClick}
          className={classNames({
            active: item.isActive,
          })}
        >
          {item.title}
        </BtnComponent>
      )}

      {item.status === STATUS_ACTIVATED_NEW
      //     && (
      //   <img
      //     src={ICON_NEW}
      //     alt="img-new"
      //     className="position-absolute icon-new"
      //   />
      // )
      }
      {item.children && (
        <>
          <div className="show-menu" onClick={toggle} />
          <ul className="sub-menu bg-sh">
            {item.children.map((subMenuItem) => (
              <MainMenuItem item={subMenuItem} key={subMenuItem.path} />
            ))}
          </ul>
        </>
      )}
    </StyledLi>
  );
}

const StyledIcon = styled.i`
  margin-right: 8px;
  color: ${COLOR_PRIMARY};
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  font: 0.8 var(--SVN-GilroyMedium);
  font-size: 18px;
`;
