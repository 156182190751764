import { useState } from "react";
import classNames from "classnames";
import MainMenu from "./MainMenu";
import Logo from "./Logo";

export default function Header() {
  const [isActiveNavbar, setIsActiveNavbar] = useState(false);

  const toggleNavBar = () => {
    setIsActiveNavbar((_isActiveNavbar) => !_isActiveNavbar);
  };

  return (
    <header>
      <nav
        className={classNames("navbar navbar-expand-lg", {
          active: isActiveNavbar,
        })}
      >
        <div className="container pr">
          <Logo />
          <MainMenu
            isActiveNavbar={isActiveNavbar}
            toggleNavBar={toggleNavBar}
          />
        </div>
      </nav>
    </header>
  );
}
