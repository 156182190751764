import _ from "lodash";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU } from "consts/book";
import LessonPlan from "./LessonPlan"

export default function SectionGiaoAn() {
  const documents = useBookStore((state) => state.documents);
  const subjectId = useAppStore((state) => state.subjectId);
  const gradeId = useAppStore((state) => state.gradeId);
  const documentsOfCurrentGrade = _.get(documents, [gradeId], []);
  const hldtDocuments = documentsOfCurrentGrade.filter(
    (item) =>
      item.category === DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU &&
      item.subject_id === subjectId
  );
	return (
          <div className="col-md-12">
              <LessonPlan
                  data={hldtDocuments.slice(0, 4)}
                  categoryBook="Bài giảng PowerPoint"
                  col="col-6 col-lg-3"
                  background="#fff"
              />
          </div>
  );
}
