import { create } from "zustand";
import _ from "lodash";
import { useAppStore } from "stores/appStore";
import {
  getGradeSubject,
  getSupplementDocs,
} from "edu_lms_v2/services/listBook";
import { TAB_COURSE } from "edu_lms/constants/type";
import {
  DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU,
  DOCUMENT_TYPE_ALL,
} from "consts/book";

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 6;

export const useHocLieuStore = create((set, get) => ({
  listHocLieu: [],
  total: 0,
  fetchingHocLieu: false,
  gradeSubjects: [],
  textSearch: "",
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,

  // state: listHocLieu
  setListHocLieu: (listHocLieu) => set({ listHocLieu }),
  fetchHocLieu: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const subjectId = useAppStore.getState().subjectId;
    const textSearch = get().textSearch;
    const page = get().page;
    const limit = get().limit;

    if (gradeId && subjectId) {
      set({ fetchingHocLieu: true });

      const res = await getSupplementDocs(
        gradeId,
        subjectId,
        DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU,
        DOCUMENT_TYPE_ALL,
        textSearch,
        limit,
        page + 1
      );

      if (res.status === 200) {
        const listHocLieu = _.isArray(res?.data?.data?.data)
          ? res?.data?.data?.data
          : [];
        const total = res?.data?.data?.total || 0;

        set({ listHocLieu, total });
      }
      set({ fetchingHocLieu: false });
    }
  },

  // state: gradeSubjects
  setGradeSubjects: (gradeSubjects) => set({ gradeSubjects }),
  fetchGradeSubjects: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const grades = useAppStore.getState().grades;

    const currentGrade = grades.find((grade) => grade.id === gradeId);
    if (currentGrade) {
      const data = {
        level_school: currentGrade.parent_id,
        type_menu: TAB_COURSE,
      };
      const res = await getGradeSubject(data);

      if (res.status === 200) {
        const gradeSubjects = res?.data?.data || {};
        set({ gradeSubjects });
      }
    }
  },

  // state: page
  setPage: (page) => set({ page }),

  // state: textSearch
  setTextSearch: (textSearch) => set({ textSearch }),
}));
