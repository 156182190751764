import { Link } from "react-router-dom";
import * as PATH from "./routePaths";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import {
  CLICK_LECTURE_LINK,
  CLICK_MOCK_TEST_LINK,
} from "edu_lms/constants/eventNameGTM";
import {
  STATUS_ACTIVATED,
  STATUS_ACTIVATED_NEW,
  STATUS_DEACTIVATED,
  USER_ROLE_TEACHER,
  USER_ROLE_STUDENT,
  USER_ROLE_PARENT,
  USER_ROLE_HOC10,
  USER_ROLE_ANONYMOUS,
  SCREEN_SIZE_MOBILE,
  SCREEN_SIZE_TABLET,
  SCREEN_SIZE_DESKTOP,
} from "./general";
import ButtonActivateBook from "components/ButtonActivateBook";

export const MENU_ITEM_TAP_HUAN = {
  title: "Tập huấn",
  icon: null,
  path: PATH.ROUTE_PATH_V3_TRAINING,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_BO_SACH_CANH_DIEU = {
  title: "Bộ sách Cánh Diều",
  icon: "fa fa-book",
  path: PATH.ROUTE_PATH_V3_BOOK_INTRO,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_VE_CHUNG_TOI = {
  title: "Về chúng tôi",
  icon: "fa fa-star",
  path: PATH.ROUTE_PATH_V3_ONEPERCENT_INTRO,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_GIOI_THIEU = {
  title: "Giới thiệu",
  icon: null,
  path: PATH.ROUTE_PATH_V3_INTRO,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: [MENU_ITEM_BO_SACH_CANH_DIEU, MENU_ITEM_VE_CHUNG_TOI],
  onClick: null,
  render: null,
};

export const MENU_ITEM_TU_SACH = {
  title: "Tủ sách",
  icon: null,
  path: PATH.ROUTE_PATH_TU_SACH,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_HOC_LIEU = {
  title: "Học liệu",
  icon: null,
  path: PATH.ROUTE_PATH_COURSE,
  status: STATUS_ACTIVATED_NEW,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_BAI_GIANG = {
  title: "Bài giảng",
  icon: null,
  path: PATH.ROUTE_PATH_V3_LIST_SLIDE,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: () => {
    setEventGTM({
      event: CLICK_LECTURE_LINK,
    });
  },
  render: null,
};

export const MENU_ITEM_LUYEN_THI = {
  title: "Đề kiểm tra",
  icon: null,
  path: PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST,
  status: STATUS_ACTIVATED_NEW,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: () => {
    setEventGTM({
      event: CLICK_MOCK_TEST_LINK,
    });
  },
  render: null,
};

export const MENU_ITEM_KICH_HOAT_SACH = {
  title: "Kích hoạt sách",
  icon: null,
  path: null,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: () => {
    return (
      <li className="d-none d-lg-flex">
        <ButtonActivateBook />
      </li>
    );
  },
};

export const MENU_ITEM_CAU_HOI_THUONG_GAP = {
  title: "Câu hỏi thường gặp",
  icon: "fa fa-question-circle",
  path: PATH.ROUTE_PATH_V3_FREQUENT_QUESTION,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_MUA_SACH = {
  title: "Mua sách",
  icon: null,
  path: PATH.ROUTE_PUBLISHER_LIST,
  status: STATUS_DEACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_DIA_CHI_MUA_SACH = {
  title: "Địa chỉ mua sách",
  icon: "fa fa-address-book",
  path: PATH.ROUTE_PUBLISHER_LIST,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_LIEN_HE = {
  title: "Liên hệ",
  icon: "fa fa-phone-square",
  path: PATH.ROUTE_PATH_V3_CONTACT,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_HO_TRO = {
  title: "Hỗ trợ",
  icon: null,
  path: null,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: [
    MENU_ITEM_CAU_HOI_THUONG_GAP,
    MENU_ITEM_DIA_CHI_MUA_SACH,
    MENU_ITEM_LIEN_HE,
  ],
  onClick: null,
  render: null,
};

export const MENU_ITEM_NOTIFICATION = {
  title: "Thông báo",
  icon: "fa fa-bell",
  path: null,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: null,
};

export const MENU_ITEM_DANG_KY = {
  title: "Đăng ký",
  icon: null,
  path: PATH.ROUTE_PATH_V3_REGISTER,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: null,
  render: () => (
    <Link
      to={PATH.ROUTE_PATH_V3_REGISTER}
      href=""
      title=""
      className="btn_register btn-pr flex-center"
    >
      Đăng ký
    </Link>
  ),
};

export const MENU_ITEM_DANG_NHAP = {
  title: "Đăng nhập",
  icon: null,
  path: null,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: () => {
    console.log("click dang nhap");
  },
  render: () => (
    <li className="">
      <button className="activated flex-center" onClick={() => {}}>
        Đăng nhập
      </button>
    </li>
  ),
};

export const MENU_ITEM_DANG_XUAT = {
  title: "Đăng xuất",
  icon: null,
  path: null,
  status: STATUS_ACTIVATED,
  visibility: [SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLET, SCREEN_SIZE_DESKTOP],
  accessibleRoles: [
    USER_ROLE_TEACHER,
    USER_ROLE_STUDENT,
    USER_ROLE_PARENT,
    USER_ROLE_HOC10,
    USER_ROLE_ANONYMOUS,
  ],
  children: null,
  onClick: () => {
    console.log("click dang xuat");
  },
  render: null,
};

export const MAIN_MENU_ITEMS = [
  MENU_ITEM_MUA_SACH,
  MENU_ITEM_TAP_HUAN,
  MENU_ITEM_GIOI_THIEU,
  MENU_ITEM_TU_SACH,
  MENU_ITEM_HOC_LIEU,
  MENU_ITEM_BAI_GIANG,
  MENU_ITEM_LUYEN_THI,
  MENU_ITEM_KICH_HOAT_SACH,
  MENU_ITEM_HO_TRO,
  // MENU_ITEM_NOTIFICATION,
  // MENU_ITEM_DANG_KY,
  // MENU_ITEM_DANG_NHAP,
];
