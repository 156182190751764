import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { onResultUserInfo } from "edu_lms/modules/selection";
import icon_logout from "edu_lms_v2/assets/img/icon-logout.svg";
import icon_edit from "edu_lms_v2/assets/img/icon-edit.svg";
import * as PATH from "consts/routePaths";
import { DEFAULT_AVATAR } from "consts/icons";
import { useAuthStore } from "stores/authStore";

export default function ProfileMenu() {
  const openLoginModal = useAuthStore((state) => state.openLoginModal);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const history = useHistory();
  const fullName =
    useSelector((state) => state.signInReducers.user_info.full_name) ||
    "user2022";
  const avatar = useSelector((state) => state.signInReducers.user_info.avatar);
  const totalNotiUnread = useSelector(
    (state) => state.notiReducer.notiUnread || 0
  );
  const userInfo = onResultUserInfo();

  const Logout = () => {
    cleanLocalStorage();
    history.push(PATH.ROUTE_PATH_V2_HOME);
  };

  return (
    <div className="login m-hidden">
      {isLoggedIn ? (
        <ul className="navbar-nav">
          <li className="mr-2">
            <div className="mr-3 position-relative">
              <Link to={PATH.ROUTE_PATH_V2_NOTIFICATION}>
                <Icon className="fa fa-bell" aria-hidden="true" />
                {totalNotiUnread > 0 && (
                  <span className="position-absolute span-number-notification">
                    {totalNotiUnread}
                  </span>
                )}
              </Link>
            </div>
          </li>
          <li className="mr-0">
            <div className="avatar__img">
              <img
                src={avatar || DEFAULT_AVATAR}
                className="fit"
                alt="Ảnh đại diện"
              />
            </div>
            <StyleFullname className="text-dark d-inline-block text-truncate">
              {fullName}
            </StyleFullname>
            <ul className="sub-menu bg-sh">
              {!(userInfo?.test_book_3_7_10 && !userInfo?.is_user_hoc10) && (
                <>
                  <li>
                    <Link to={PATH.ROUTE_PATH_V3_UPDATEINFORMATION}>
                      <i className="icon">
                        <img src={icon_edit} alt="Cập nhật thông tin" />
                      </i>
                      Cập nhật thông tin
                    </Link>
                  </li>
                  <li>
                    <Link to={PATH.ROUTE_PATH_V3_TRAINING}>
                      <i
                        className="fa fa-book mr-2 monkey-color-orange"
                        aria-hidden="true"
                      />
                      Tập huấn
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link to={""} onClick={Logout}>
                  <i className="icon">
                    <img src={icon_logout} alt="Đăng xuất" />
                  </i>{" "}
                  Đăng xuất
                </Link>
              </li>
            </ul>
            <span className="show-menu"></span>
          </li>
        </ul>
      ) : (
        <div className="login__before flex-lc">
          <Link
            to={PATH.ROUTE_PATH_V3_REGISTER}
            href=""
            title=""
            className="btn_register btn-pr flex-center"
          >
            Đăng ký
          </Link>
          <div
            title="Đăng nhập"
            className="btn_login flex-center cursor"
            onClick={openLoginModal}
          >
            Đăng nhập
          </div>
        </div>
      )}
    </div>
  );
}

const StyleFullname = styled.div`
  padding: 26px 0;
  font-size: 17px;
  max-width: 130px;
`;

const Icon = styled.i`
  color: #ff7707;
`;
