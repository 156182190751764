import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import styled from "styled-components";
import * as PATH from "consts/routePaths";
import LoginWrapper from "edu_lms_v2/modules/Login/LoginWrapper";
import LoginSuccessModal from "edu_lms_v2/modules/Login/LoginSuccessModal";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { onResultUserInfo } from "edu_lms/modules/selection";
import ModalActiveBook from "edu_lms_v2/modules/ActiveBook/ModalActiveBook";
import ModalRequireLogin from "edu_lms_v2/modules/ActiveBook/ModalRequireLogin";
import ModalActiveBookSuccess from "edu_lms_v2/modules/ActiveBook/ModalActiveBookSuccess";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import ForgotPassword from "edu_lms_v2/modules/ForgotPassword";
import icon_active from "edu_lms_v2/assets/img/icon-active.svg";
import icon_logout from "edu_lms_v2/assets/img/icon-logout.svg";
import icon_edit from "edu_lms_v2/assets/img/icon-edit.svg";
import NavbarMobile from "./NavbarMobile";
import NavbarDesktop from "./NavbarDesktop";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";
import PopupCheckJobGoogle from "edu_lms_v2/modules/Login/PopupCheckJobGoogle";
import { MENU_PATH } from "./menuPath";

export default function Header({ openActiveBook, onCloseActiveBook }) {
  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const { isOpenTraining } = JSON.parse(trainingConfig);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [loginSuccessModalShow, setLoginSuccessModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isActiveNavbar, setActiveNavbar] = useState(false);
  const [isRequireLogin, setRequireLogin] = useState(false);
  const [isActiveModalBook, setActiveModalBook] = useState(false);
  const [isActiveModalBookSuccess, setActiveModalBookSuccess] = useState(false);
  const [isActiveListItemNavbar, setActiveListItemNavbar] = useState([]);
  const [activedBook, setActivedBook] = useState({});
  const [isActiveForgotPw, setActiveForgotPw] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userInfo = onResultUserInfo();
  const [activeMenu, setActiveMenu] = useState("");
  const fullName =
    useSelector((state) => state.signInReducers.user_info.full_name) ||
    "user2022";
  const avatar = useSelector((state) => state.signInReducers.user_info.avatar);
  const totalNotiUnread = useSelector((state) => state.notiReducer.notiUnread);
  const [checkJob, setCheckJob] = useState();
  const [showCheckJobGoogle, setShowCheckJobGoogle] = useState(false);

  useEffect(() => {
    if (token) {
      // getListNoti({
      //   limit: TYPES.LIMIT_NOTI,
      //   page: TYPES.PAGE_DEFAULT,
      //   read: TYPES.UNREAD_NOTI,
      // }).then((res) => {
      //   if (res?.data?.status === "success") {
      //     dispatch(onDispatchCountUnreadNoti(res.data.data.total));
      //   }
      // });
    }
    if (userInfo) {
      if (userInfo.full_name)
        dispatch(onDispatchDataInfo({ full_name: userInfo.full_name }));
      if (userInfo.avatar)
        dispatch(onDispatchDataInfo({ avatar: userInfo.avatar }));
    }
  }, []);

  useEffect(() => {
    setIsLoggedIn(Boolean(token));
  }, [token]);

  useEffect(() => {
    if (openActiveBook) {
      onActiveBook();
    }
  }, [openActiveBook]);

  useEffect(() => {
    if (!isActiveModalBook) {
      onCloseActiveBook();
    }
  }, [isActiveModalBook]);

  const toggleNavbar = () => {
    setActiveNavbar(!isActiveNavbar);
  };
  const toggleListItem = (e, name) => {
    e.stopPropagation();
    name == activeMenu ? setActiveMenu("") : setActiveMenu(name);
    setActiveListItemNavbar(!isActiveListItemNavbar);
  };
  const Logout = () => {
    setIsLoggedIn(false);
    cleanLocalStorage();
    dispatch(onDispatchDataInfo({ avatar: "" }));
    history.push(PATH.ROUTE_PATH_V2_HOME);
  };
  const onLoginSuccess = (data) => {
    !data?.job_id && setShowCheckJobGoogle(true);
    setCheckJob(data);
    setLoginModalShow(false);
    data?.job_id && setLoginSuccessModalShow(true);
  };
  const onActiveBook = () => {
    if (isLoggedIn) {
      setActiveModalBook(true);
      setRequireLogin(false);
    } else {
      setActiveModalBook(false);
      setRequireLogin(true);
    }
  };
  const onRequireLogin = () => {
    setRequireLogin(false);
    setLoginModalShow(true);
  };
  const onActiveBookSuccess = (book) => {
    setActiveModalBook(false);
    setActiveModalBookSuccess(true);
    setActivedBook(book);
  };
  const onActiveForgotPw = () => {
    setLoginModalShow(false);
    setActiveForgotPw(true);
  };

  const handleShowLogo = () => {
    const product = process.env.REACT_APP_APP_ID;
    const logoHoc10 = "/assets/img/updated_logo.png";
    const logoTutoring = "/assets/img/logo-tutoring1.png";
    if (Number(product) === HOC10_APP_ID) return logoHoc10;
    if (Number(product) === TUTORING_APP_ID) return logoTutoring;
  };

  return (
    <>
      <header>
        <nav
          className={classNames("navbar navbar-expand-lg", {
            active: isActiveNavbar,
          })}
          onClick={toggleNavbar}
        >
          <div className="container pr">
            <Link to={PATH.ROUTE_PATH_V2_HOME} className="logo width-logo">
              <img
                src={handleShowLogo()}
                className={classNames({
                  "w-50":
                    Number(process.env.REACT_APP_APP_ID) === TUTORING_APP_ID,
                })}
                alt="Logo Hoc10"
              />
            </Link>
            <button
              className={classNames("navbar-toggler border-0 ", {
                active: isActiveNavbar,
              })}
              type="button"
              onClick={toggleNavbar}
            >
              <span></span>
            </button>
            <div className="navbar-collapse" id="navbar">
              <NavbarMobile
                menuItems={MENU_PATH}
                toggleListItem={toggleListItem}
                onActiveBook={onActiveBook}
                isLoggedIn={isLoggedIn}
                totalNotiUnread={totalNotiUnread}
                activeMenu={activeMenu}
              />
              <NavbarDesktop
                onActiveBookSuccess={onActiveBookSuccess}
                menuItems={MENU_PATH}
                onActiveBook={onActiveBook}
              />
              <div className="login m-hidden">
                {isLoggedIn ? (
                  <>
                    <ul className="navbar-nav">
                      <li className="mr-0">
                        <div className="mr-3 position-relative">
                          <Link to={PATH.ROUTE_PATH_V2_NOTIFICATION}>
                            <Icon className="fa fa-bell" aria-hidden="true" />
                            {totalNotiUnread > 0 && (
                              <span className="position-absolute span-number-notification">
                                {totalNotiUnread}
                              </span>
                            )}
                          </Link>
                        </div>
                      </li>
                      <li className="mr-0">
                        <div className="avatar__img">
                          <img
                            src={avatar || "/assets/img/avt2.png"}
                            className="fit"
                            alt="Ảnh đại diện"
                          />
                        </div>
                        <StyleFullname className="text-dark d-inline-block text-truncate">
                          {fullName}
                        </StyleFullname>
                        <ul className="sub-menu bg-sh">
                          {!(
                            userInfo?.test_book_3_7_10 &&
                            !userInfo?.is_user_hoc10
                          ) && (
                            <>
                              <li>
                                <Link to={PATH.ROUTE_PATH_V3_UPDATEINFORMATION}>
                                  <i className="icon">
                                    <img
                                      src={icon_edit}
                                      alt="Cập nhật thông tin"
                                    />
                                  </i>
                                  Cập nhật thông tin
                                </Link>
                              </li>
                              <li>
                                <Link to={PATH.ROUTE_PATH_V3_TRAINING}>
                                  <i
                                    className="fa fa-book mr-2 monkey-color-orange"
                                    aria-hidden="true"
                                  />
                                  Tập huấn
                                </Link>
                              </li>
                            </>
                          )}
                          <li>
                            <Link to={""} onClick={Logout}>
                              <i className="icon">
                                <img src={icon_logout} alt="Đăng xuất" />
                              </i>{" "}
                              Đăng xuất
                            </Link>
                          </li>
                        </ul>
                        <span className="show-menu"></span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <div className="login__before flex-lc">
                    <Link
                      to={PATH.ROUTE_PATH_V3_REGISTER}
                      href=""
                      title=""
                      className="btn_register btn-pr flex-center"
                    >
                      Đăng ký
                    </Link>
                    <div
                      title="Đăng nhập"
                      className="btn_login flex-center cursor"
                      onClick={() => setLoginModalShow(true)}
                    >
                      Đăng nhập
                    </div>
                  </div>
                )}
              </div>

              <div className="bottom m-show-flex">
                {isLoggedIn ? (
                  <>
                    <div
                      title="Kích hoạt"
                      className="btn-login flex-center"
                      data-toggle="modal"
                      data-target="#modal-login"
                      onClick={onActiveBook}
                    >
                      <img src={icon_active} className="mr-3" /> Kích hoạt sách
                    </div>

                    <Link
                      to={""}
                      title="Đăng xuất"
                      className="btn-login flex-center"
                      data-toggle="modal"
                      data-target="#modal-login"
                      onClick={Logout}
                    >
                      <img src={icon_logout} className="mr-3" /> Đăng xuất
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      title="kích hoạt"
                      className="btn-login flex-center"
                      data-toggle="modal"
                      data-target="#modal-login"
                      onClick={onActiveBook}
                    >
                      <img src={icon_active} className="mr-3" /> Kích hoạt sách
                    </div>
                    <div
                      title="Đăng nhập"
                      className="btn-login flex-center cursor"
                      data-toggle="modal"
                      data-target="#modal-login"
                      onClick={() => setLoginModalShow(true)}
                    >
                      <img src={icon_logout} className="mr-3" /> Đăng nhập
                    </div>
                  </>
                )}
              </div>
            </div>
            {isLoggedIn ? (
              <>
                <div className="avatar flex-lc" id="avatar-desktop-screen">
                  <div className="avatar__img">
                    <img
                      src={avatar || "/assets/img/avt2.png"}
                      className="fit"
                      alt="Ảnh đại diện"
                    />
                  </div>
                  <FullNameMobile className="avatar__name avatar-toggle postion-absolute text-truncate">
                    {fullName}
                  </FullNameMobile>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <Link
                  to={PATH.ROUTE_PATH_TU_SACH}
                  className="activated flex-center btn-activate-book mr-2 d-md-block d-lg-none"
                >
                  Tủ sách
                </Link>
                <Link
                  to={PATH.ROUTE_PATH_V3_REGISTER}
                  href=""
                  title=""
                  className="btn-register flex-center m-show-flex"
                >
                  Đăng Ký
                </Link>
              </div>
            )}
          </div>
        </nav>
        <div className="box-fix-right">
          {/* <a href="javascript:;" className="backtop flex-center">
            <i className="icon icon-arrup"></i>
          </a>
          <a href="" title="" className="messenger flex-center">
            <i className="icon icon-messenger"></i>
          </a>
          <a href="" title="" className="qs flex-center">
            <i className="icon icon-qs"></i>
          </a> */}
        </div>
      </header>

      <LoginWrapper
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onLoginSuccess={onLoginSuccess}
        onActiveForgotPw={onActiveForgotPw}
      />

      <LoginSuccessModal
        show={loginSuccessModalShow}
        checkJob={checkJob}
        onHide={() => {
          setLoginSuccessModalShow(false);
        }}
        params={history.location.search || ""}
      />
      <ModalActiveBook
        show={isActiveModalBook}
        onHide={() => setActiveModalBook(false)}
        onActiveBook={onActiveBook}
        onActiveBookSuccess={onActiveBookSuccess}
      />
      <ModalRequireLogin
        show={isRequireLogin}
        onHide={() => setRequireLogin(false)}
        onRequireLogin={onRequireLogin}
      />

      <ModalActiveBookSuccess
        show={isActiveModalBookSuccess}
        onHide={() => setActiveModalBookSuccess(false)}
        data={activedBook}
      />
      <ForgotPassword
        isActiveForgotPw={isActiveForgotPw}
        setActiveForgotPw={setActiveForgotPw}
        setLoginModalShow={setLoginModalShow}
        isPopupLogin={true}
      />

      <PopupCheckJobGoogle
        show={showCheckJobGoogle}
        onHide={() => setShowCheckJobGoogle(false)}
      />
    </>
  );
}
const StyleFullname = styled.div`
  padding: 26px 0;
  font-size: 17px;
  max-width: 130px;
`;
const Icon = styled.i`
  color: #ff7707;
`;

const FullNameMobile = styled.div`
  right: 50px;
  max-width: 150px;
`;
