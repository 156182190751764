import { useEffect } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Intro from "./components/Intro";
import Slide from "./components/Slide";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Loading from "edu_lms_v2/components/Loading";
import GradeFilter from "components/GradeFilter";
import SubjectFilter from "components/SubjectFilter";
import { useGiaoanStore } from "stores/giaoanStore";
import { useAppStore } from "stores/appStore";
import { DEFAULT_SUBJECT_SLUG_BY_GRADE } from "consts/book";
import { ROUTE_PATH_V3_LIST_SLIDE } from "consts/routePaths";
import StudyWeekFilter from "components/StudyWeekFilter";
import { useQueryState } from "hooks/useQueryState";
import useQuery from "modules/TuSach/useQuery";
import SEO from "edu_lms/components/Seo";
import { getMetaData } from "./constant";
import SearchForm from "./SearchForm";

export default function SlideLibrary() {
  useScrollToTop();
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const params = useParams();
  const query = useQuery();

  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const setSubjectId = useAppStore((state) => state.setSubjectId);
  const setStudyWeekId = useAppStore((state) => state.setStudyWeekId);
  const studyWeekId = useAppStore((state) => state.studyWeekId);
  const listGiaoAn = useGiaoanStore((state) => state.listGiaoAn);
  const fetchGiaoAn = useGiaoanStore((state) => state.fetchGiaoAn);
  const setLimit = useGiaoanStore((state) => state.setLimit);
  const fetchingGiaoAn = useGiaoanStore((state) => state.fetchingGiaoAn);
  const fetchGradeSubjects = useGiaoanStore(
    (state) => state.fetchGradeSubjects
  );
  const textSearch = useGiaoanStore((state) => state.textSearch);
  const page = useGiaoanStore((state) => state.page);
  const limit = useGiaoanStore((state) => state.limit);
  const total = useGiaoanStore((state) => state.total);
  const gradeSubjects = useGiaoanStore((state) => state.gradeSubjects);
  const setPage = useGiaoanStore((state) => state.setPage);
  const setTextSearch = useGiaoanStore((state) => state.setTextSearch);

  const [queryWeek, setQueryWeek] = useQueryState("tuan");
  const [queryTextSearch] = useQueryState("tieude");

  const currentSubject = subjects.find((subject) => subject.id === subjectId);
  const currentGrade = grades.find((grade) => grade.id === gradeId);

  useEffect(() => {
    setLimit(6);
    if (queryWeek) {
      setStudyWeekId(Number(queryWeek));
    }
    if (queryTextSearch) {
      setTextSearch(queryTextSearch);
    }
  }, []);

  useEffect(() => {
    fetchGradeSubjects();
  }, [currentGrade?.parent_id, fetchGradeSubjects]);

  useEffect(() => {
    fetchGiaoAn();
  }, [gradeId, subjectId, studyWeekId, page, limit, fetchGiaoAn]);

  useEffect(() => {
    const setDefaultSubject = () => {
      // Dang co mot table manage viec display grade, subject nen phai lay o 2 nguon moi du data
      // TODO: toi uu data management cho viec hien thi grade, subject tren caca page
      const _currentGrade = gradeSubjects?.grades?.find(
        (grade) => grade.grade_id === gradeId
      );
      if (_currentGrade) {
        const currentGrade = grades.find((grade) => grade.id === gradeId);
        // 1. Check subject value from store
        if (subjectId) {
          const currentSubjectFromStore = subjects.find(
            (subject) => subject.id === subjectId
          );
          if (
            currentSubjectFromStore &&
            _currentGrade?.has_subject_ids?.includes(currentSubjectFromStore.id)
          ) {
            return;
          }
        }
        // 2. Check subject value from url params
        const urlParamSubject = params?.subjectSlug;
        if (urlParamSubject) {
          const currentSubjectFromUrlParams = subjects.find(
            (subject) =>
              subject.slug === urlParamSubject &&
              subject.grade_id === currentGrade?.parent_id
          );
          if (
            currentSubjectFromUrlParams &&
            _currentGrade?.has_subject_ids?.includes(
              currentSubjectFromUrlParams.id
            )
          ) {
            setSubjectId(currentSubjectFromUrlParams.id);
            return;
          }
        }
        // 3. Check default subject value
        const defaultSubjectSlug =
          DEFAULT_SUBJECT_SLUG_BY_GRADE[currentGrade?.parent_id];
        if (defaultSubjectSlug) {
          const defaultSubject = subjects.find(
            (subject) =>
              subject.slug === defaultSubjectSlug &&
              subject.grade_id === currentGrade?.parent_id
          );
          if (
            defaultSubject &&
            _currentGrade?.has_subject_ids?.includes(defaultSubject.id)
          ) {
            setSubjectId(defaultSubject.id);
            return;
          }
        }
        const filteredSubjects = subjects.filter((subject) =>
          _currentGrade?.has_subject_ids?.includes(subject.id)
        );
        // 4. Check subject value from the first item of subject list
        const defaultSubject = _.get(filteredSubjects, "0");
        if (defaultSubject) {
          setSubjectId(defaultSubject.id);
        }
      }
    };

    setDefaultSubject();
  }, [gradeId, gradeSubjects?.grades, grades, params?.subjectSlug]);

  const buildUrl = () => {
    if (currentSubject?.slug && currentGrade?.slug) {
      const url = `${ROUTE_PATH_V3_LIST_SLIDE}${currentSubject.slug}/${currentGrade.slug}/`;
      const data = _.pickBy(
        {
          tuan: studyWeekId,
          tieude: textSearch,
        },
        (value) => !!value
      );
      const searchParams = new URLSearchParams(data);

      return `${url}?${searchParams}`;
    }
    return "";
  };

  useEffect(() => {
    if (currentSubject?.slug && currentGrade?.slug) {
      history.replace(buildUrl());
    }
  }, [currentGrade?.slug, currentSubject?.slug]);

  const titleSubjectAndGrade = `${currentSubject?.title} ${currentGrade?.name}`;
  const metaData =
    currentSubject?.slug && currentGrade?.slug
      ? getMetaData(
          `Giáo án - bài giảng điện tử môn ${titleSubjectAndGrade}`,
          `Giáo án - bài giảng điện tử môn ${titleSubjectAndGrade} bộ sách Cánh Diều được xây dựng bởi đội ngũ giáo viên giỏi, giàu kinh nghiệm.`,
          buildUrl()
        )
      : getMetaData(
          "Thư viện giáo án - bài giảng điện tử sách Cánh Diều | Hoc10",
          "Giáo án - bài giảng điện tử lớp 1 đến lớp 12 của bộ sách Cánh Diều được xây dựng bởi đội ngũ giáo viên giỏi, giàu kinh nghiệm.",
          buildUrl()
        );

  return (
    <main>
      <SEO data={metaData} />
      <Intro />
      <div className="hoc10-lesson-library">
        <div className="container">
          <div className="filter filter-horizontal mt-3 mb-4 p-0">
            <GradeFilter
              direction="horizontal"
              onChange={() => {
                setPage(0);
              }}
            />
          </div>
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="flex-lc justify-content-between col-lg-9 mb-lg-5">
              <div className="box-select">
                <StudyWeekFilter
                  onChange={(w) => {
                    setPage(0);
                    setQueryWeek(w?.value || "");
                  }}
                />
              </div>

              <SearchForm />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 p-0">
              <div className="filter">
                <GradeFilter
                  onChange={() => {
                    setPage(0);
                  }}
                />
                <SubjectFilter
                  page={ROUTE_PATH_V3_LIST_SLIDE}
                  onChange={() => {
                    setPage(0);
                  }}
                />
              </div>
            </div>
            {!fetchingGiaoAn ? (
              <>
                <div className="col-lg-9 col-md-8">
                  <div
                    className={classNames("row", {
                      "justify-content-center": listGiaoAn.length == 0,
                    })}
                  >
                    {listGiaoAn.length > 0 ? (
                      listGiaoAn.map((slide, index) => (
                        <Slide key={index} slide={slide} />
                      ))
                    ) : (
                      <div className="monkey-fz-24">Sắp ra mắt</div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-9 col-md-8">
                <Loading />
              </div>
            )}
          </div>
          <div className="loading flex-center">
            {total > 1 && (
              <ReactPaginate
                previousLabel={""}
                previousClassName={"icon icon-prev"}
                nextLabel={""}
                nextClassName={"icon icon-next"}
                breakLabel={"..."}
                pageCount={Math.ceil(total / limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(data) => {
                  setPage(data.selected);
                }}
                forcePage={page}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousLinkClassName="page-link page-link--prev"
                nextLinkClassName="page-link page-link--next"
                hrefAllControls
                hrefBuilder={() => {
                  return "javascript:;";
                }}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
