import * as PATH from "consts/routePaths";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import {
  CLICK_LECTURE_LINK,
  CLICK_MOCK_TEST_LINK,
} from "edu_lms/constants/eventNameGTM";
import {
  MENU_INTRO,
  MENU_SUPPORT,
  MENU_KNOWLEDGE,
} from "edu_lms/constants/type";

const MENU_PATH = [
  // {
  //   title: "Mua sách",
  //   // path: PATH.ROUTE_PUBLISHER_LIST,
  //   path: "https://sachcanhdieu.vn/",
  //   isRedirect: true // them bien de open sang tab moi theo order tu team hoc10
  // },
  {
    title: "Tập huấn",
    path: PATH.ROUTE_PATH_V3_TRAINING,
  },
  {
    title: "Giới thiệu",
    path: PATH.ROUTE_PATH_V3_INTRO,
    mobileActive: MENU_INTRO,
    subMenu: [
      {
        title: "Bộ sách Cánh Diều",
        iconSrc: "/assets/img/icon-mn1.svg",
        path: PATH.ROUTE_PATH_V3_BOOK_INTRO,
        show: true,
      },
      {
        title: "Về chúng tôi",
        iconSrc: "/assets/img/icon-mn3.svg",
        path: PATH.ROUTE_PATH_V3_ONEPERCENT_INTRO,
        show: true,
      },
    ],
  },
  {
    title: "Tủ sách",
    path: PATH.ROUTE_PATH_TU_SACH,
  },
  {
    title: "Học liệu",
    path: PATH.ROUTE_PATH_COURSE,
  },
  {
    title: "Bài giảng",
    path: `${PATH.ROUTE_PATH_V3_LIST_SLIDE}`,
    eventClick: () =>
      setEventGTM({
        event: CLICK_LECTURE_LINK,
      }),
  },
  {
    title: "Đề kiểm tra",
    path: PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST,
    eventClick: () =>
      setEventGTM({
        event: CLICK_MOCK_TEST_LINK,
      }),
  },
  // {
  //   title: "KBT",
  //   path: null,
  //   mobileActive: MOBILE_MENU_EXERCISE,
  //   subMenu: [
  //     {
  //       title: "Lớp học của tôi",
  //       iconSrc: "/assets/img/icon-mn3.svg",
  //       path: PATH.ROUTE_PATH_CLASSROOM,
  //       show: true,
  //     },
  //     {
  //       title: "Học liệu của tôi",
  //       iconSrc: "/assets/img/icon-mn1.svg",
  //       path: PATH.ROUTE_PATH_MY_STUDY,
  //       show: true,
  //     },
  //   ],
  // },
  {
    isMobileOnly: true,
    isPrivate: true,
    title: "Thông báo",
    path: PATH.ROUTE_PATH_V2_NOTIFICATION,
  },
  {
    isMobileOnly: true,
    isPrivate: true,
    title: "Cập nhật thông tin",
    path: PATH.ROUTE_PATH_V3_UPDATEINFORMATION,
  },
  {
    title: "Kiến thức",
    path: PATH.ROUTE_BLOG_KNOWLEDGE,
    mobileActive: MENU_KNOWLEDGE,
    subMenu: [
      {
        title: "Tiểu học",
        iconSrc: "/assets/img/icon-header-block.svg",
        path: `${PATH.ROUTE_BLOG_KNOWLEDGE}tieu-hoc`,
        show: true,
      },
      {
        title: "Trung học cơ sở",
        iconSrc: "/assets/img/icon-header-block1.svg",
        path: `${PATH.ROUTE_BLOG_KNOWLEDGE}trung-hoc-co-so`,
        show: true,
      },
      {
        title: "Trung học phổ thông",
        iconSrc: "/assets/img/icon-header-block2.svg",
        path: `${PATH.ROUTE_BLOG_KNOWLEDGE}trung-hoc-pho-thong`,
        show: true,
      },
      {
        title: "Kiến thức khác",
        iconSrc: "/assets/img/icon-header-block3.svg",
        path: PATH.ROUTE_BLOG_KNOWLEDGE_OTHER,
        show: true,
      },
    ],
  },
  {
    title: "Kích hoạt sách",
    path: null,
    isActiveBook: true,
  },
  {
    title: "Hỗ trợ",
    path: null,
    mobileActive: MENU_SUPPORT,
    subMenu: [
      {
        title: "Câu hỏi thường gặp",
        iconSrc: "/assets/img/icon-question.svg",
        path: PATH.ROUTE_PATH_V3_FREQUENT_QUESTION,
        show: true,
      },
      {
        title: "Địa chỉ mua sách",
        iconClass: "fa fa-address-book mr-2 monkey-color-orange",
        path: PATH.ROUTE_PUBLISHER_LIST,
        show: true,
      },
      {
        title: "Liên hệ",
        iconSrc: "/assets/img/icon-phone.svg",
        path: PATH.ROUTE_PATH_V3_CONTACT,
        show: true,
      },
    ],
  },
  // {
  //   isMobileOnly: true,
  //   title: "Tập huấn",
  //   path: PATH.ROUTE_PATH_V3_TRAINING,
  // },
];

export { MENU_PATH };
