const { useBookStore } = require("stores/bookStore");

export default function ButtonActivateBook() {
  const openActivateBookModal = useBookStore(
    (state) => state.openActivateBookModal
  );

  return (
    <button className="activated flex-center btn-activate-book" onClick={openActivateBookModal}>
      <i className="icon icon-key" /> Kích hoạt sách
    </button>
  );
}
