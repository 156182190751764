import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";

export default function Logo() {
  return (
    <Link to={PATH.ROUTE_PATH_V2_HOME} className="logo width-logo">
      <img src="/assets/img/updated_logo.png" alt="Logo Hoc10" />
    </Link>
  );
}
