import { create } from "zustand";
import _ from "lodash";
import { useAppStore } from "stores/appStore";
import { getGradeSubject } from "edu_lms_v2/services/listBook";
import { getListSlide } from "edu_lms/services/slide";
import { TAB_SLIDES } from "edu_lms/constants/type";

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 6;

export const useGiaoanStore = create((set, get) => ({
  listGiaoAn: [],
  total: 0,
  fetchingGiaoAn: false,
  gradeSubjects: [],
  textSearch: "",
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,

  // state: listGiaoAn
  setListGiaoAn: (listGiaoAn) => set({ listGiaoAn }),
  setLimit: (limit) => set({ limit }),
  fetchGiaoAn: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const subjectId = useAppStore.getState().subjectId;
    const textSearch = get().textSearch;
    const studyWeekId = useAppStore.getState().studyWeekId;
    const page = get().page;
    const limit = get().limit;

    if (gradeId && subjectId) {
      set({ fetchingGiaoAn: true });
      const params = {
        gradeId,
        subjectId,
        title: textSearch,
        page: page + 1,
        limit,
        week_id: studyWeekId,
      };
      const res = await getListSlide(params);
      if (res.status === 200) {
        const listGiaoAn = _.isArray(res?.data?.data?.data)
          ? res?.data?.data?.data
          : [];
        const total = res?.data?.data?.total || 0;

        set({ listGiaoAn, total });
      }
      set({ fetchingGiaoAn: false });
    }
  },

  // state: gradeSubjects
  setGradeSubjects: (gradeSubjects) => set({ gradeSubjects }),
  fetchGradeSubjects: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const grades = useAppStore.getState().grades;

    const currentGrade = grades.find((grade) => grade.id === gradeId);
    if (currentGrade) {
      const data = {
        level_school: currentGrade.parent_id,
        type_menu: TAB_SLIDES,
      };
      const res = await getGradeSubject(data);

      if (res.status === 200) {
        const gradeSubjects = res?.data?.data || {};
        set({ gradeSubjects });
      }
    }
  },

  // state: page
  setPage: (page) => set({ page }),

  // state: textSearch
  setTextSearch: (textSearch) => set({ textSearch }),
}));
